var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"label":_vm.$t('lbl.from'),"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedFromDateFormatted),callback:function ($$v) {_vm.computedFromDateFormatted=$$v},expression:"computedFromDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","min":new Date(_vm.minDate).toISOString().substr(0, 10),"locale":"es","show-current":false},on:{"input":function($event){_vm.menuFrom = false
          !_vm.date.indefinido ? (_vm.menuTo = true) : (_vm.menuTo = false)
          _vm.date.from && !_vm.date.indefinido
            ? (_vm.date.to = _vm.$moment(_vm.date.from)
              .add('days', 1)
              .toDate()
              .toISOString()
              .substr(0, 10))
            : (_vm.date.to = null)}},model:{value:(_vm.date.from),callback:function ($$v) {_vm.$set(_vm.date, "from", $$v)},expression:"date.from"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"label":_vm.$t('lbl.to'),"disabled":_vm.date.from === null || _vm.date.indefinido,"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedToDateFormatted),callback:function ($$v) {_vm.computedToDateFormatted=$$v},expression:"computedToDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es","scrollable":true,"show-current":false,"show-adjacent-months":false,"min":_vm.date.from
            ? _vm.$moment(_vm.date.from)
              .toDate()
              .toISOString()
              .substr(0, 10)
            : ''},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.date.to),callback:function ($$v) {_vm.$set(_vm.date, "to", $$v)},expression:"date.to"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.datesStopSale.length > 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteDateStopSale(_vm.pos)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,3649363824)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.pos === 0 && !_vm.date.indefinido)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":_vm.addDateStopSale}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,3061066716)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[(_vm.datesStopSale.length === 1)?_c('v-switch',{staticClass:"mt-1 ml-3",attrs:{"label":"Indefinido","hide-details":""},on:{"click":_vm.changeIndefinido},model:{value:(_vm.date.indefinido),callback:function ($$v) {_vm.$set(_vm.date, "indefinido", $$v)},expression:"date.indefinido"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }