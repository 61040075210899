<template>
  <v-row>
    <!-- kitchen sink
    - ${$t('menu.comitions')}
    -->
    <v-col
      v-if="!isLoading"
      cols="12"
    >
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.stopSale')}`">
        <StopSaleList v-if="user.nivel === 0 || user.nivel === -1" />
        <StopSaleListProv
          v-if="user.nivel === 1"
          :user="user"
        />
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import StopSaleList from './tablas/StopSaleList.vue'
import StopSaleListProv from './tablas/StopSaleListProv.vue'

export default {
  components: {
    AppCardCode,
    StopSaleList,
    StopSaleListProv,
  },
  data() {
    return {
      isLoading: true,
      user: {},
    }
  },
  mounted() {
    this.profile()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false

          // this.isLoading = false
        })
    },
  },
}
</script>
