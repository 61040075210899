<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row v-if="!$store.state.app.onlyShow">
        <v-col
          cols="12"
          md="12"
          class="text-center"
        >
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                :disabled="stopSale.products.length === 0"
                class="mb-4 me-3"
                @click="clearItem()"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="permisos.includes('stopSale:edit') || permisos.includes('*')"
                :disabled="stopSale.products.length === 0"
                color="primary"
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="4"
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.products"
                :items="products"
                :label="$t('lbl.product')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="slug"
                multiple
                @change="setProduct"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.product') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="showProveedor"
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.proveedor_id"
                :items="proveedores"
                :label="$t('lbl.proveedor')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setProveedor"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.proveedor') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="showContrate && contrates.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.contrate_id"
                :items="contrates"
                :label="$t('lbl.contrato')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setContrate"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.contrato') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--CARS-->
            <v-col
              v-if="categoryContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.category_id"
                :items="categoryContrate"
                :label="$t('lbl.cat')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.cat') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="marcasContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.marca_modelo_id"
                :items="marcasContrate"
                :label="$t('lbl.brandModel')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.brandModel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--FLIGHTS-->
            <v-col
              v-if="flightContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.flight_id"
                :items="flightContrate"
                :label="$t('lbl.vuelo')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.vuelo') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--HOTELS-->
            <v-col
              v-if="hotelesContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.hotel_id"
                :items="hotelesContrate"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setHotel"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.hotel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="roomsContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.room_id"
                :items="roomsContrate"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.room') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--TEMPORADAS
            <v-col
              v-if="temporadas.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="stopSale.temporada_id"
                :items="temporadas"
                :label="$t('lbl.temporadas')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setTemporada"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.temporadas') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>-->
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
          offset-md="1"
        >
          <DateStopSale
            v-for="(date, ind) in datesStopSale"
            :key="ind"
            :date="date"
            :pos="ind"
          />
          <!--<v-row>
            <v-col
              cols="12"
              md="12"
              class="text-center"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                ></v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-btn
                    v-if="permisos.includes('stopSale:edit') || permisos.includes('*')"
                    color="primary"
                    large
                    block
                    :loading="loading"
                    @click="save()"
                  >
                    <span>{{ $t('btn.save') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                ></v-col>
              </v-row>
            </v-col>
          </v-row>-->
        </v-col>
      </v-row>

      <!--<v-row>
        <v-col
          v-if="permisos.includes('flights:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            v-if="currentTab === 0"
            color="primary"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="$store.state.app.itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>-->
    </v-card-text>

    <v-tabs v-model="currentTab">
      <v-tab v-if="productsPermit.includes('vuelos')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiAirplane }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.flights') }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="productsPermit.includes('cars')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiCar }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.cars') }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="productsPermit.includes('hotels')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiOfficeBuilding }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.hotels') }}</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="currentTab">
      <v-tab-item v-if="productsPermit.includes('vuelos')">
        <FlightsTab
          v-if="showTabs"
          :proveedores="proveedoresFlight"
          :contrates="contratesFlight"
          :flights="flightsList"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>
      <v-tab-item v-if="productsPermit.includes('cars')">
        <CarsTab
          v-if="showTabs"
          :proveedores-car="proveedoresCar"
          :contrates-car="contratesCar"
          :categories-car="categoriesCar"
          :marcas-car="marcasCar"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>
      <v-tab-item v-if="productsPermit.includes('hotels')">
        <HotelsTab
          v-if="showTabs"
          :proveedores-hotel="proveedoresHotel"
          :contrates-hotel="contratesHotel"
          :hotels="hotelesList"
          :rooms="roomsList"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiAirplane,
  mdiCar,
  mdiOfficeBuilding,
  mdiTrashCan,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
// import FlightTab from '../tabs/Flights.vue'

import CarsTab from '../tabs/Cars.vue'
import HotelsTab from '../tabs/Hotels.vue'
import FlightsTab from '../tabs/Flights.vue'
import DateStopSale from '../utils/DateStopSale.vue'

export default {
  components: {
    CarsTab,
    HotelsTab,
    FlightsTab,
    DateStopSale,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      loading: false,
      currentTab: 0,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiAirplane,
        mdiCar,
        mdiOfficeBuilding,
        mdiTrashCan,
      },
      itemsPerPage: 10,
      permisos: sessionStorage.getItem('permisos_auth'),
      stopSale: {},
      products: [],
      proveedoresHotel: [],
      idsProveedoresHotel: [],
      proveedoresCar: [],
      idsProveedoresCar: [],
      proveedoresFlight: [],
      proveedores: [],
      minDate: Date.now(),
      maxDate: Date.now(),
      menuFrom: false,
      menuTo: false,
      contrates: [],
      contratesHotel: [],
      hotelesList: [],
      hotelesContrate: [],
      roomsList: [],
      roomsContrate: [],
      flightsList: [],
      contratesCar: [],
      categoriesCar: [],
      marcasCar: [],
      contratesFlight: [],
      categoryContrate: [],
      marcasContrate: [],
      flightContrate: [],
      temporadas: [],
      typeAfiliados: [],
      showTabs: true,
      items: [],
      productsPermit: [],
    }
  },
  computed: {
    ...mapState({
      datesStopSale: state => state.app.datesStopSale,
    }),
    computedFromDateFormatted() {
      return this.stopSale.from ? this.$moment(this.stopSale.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.stopSale.to ? this.$moment(this.stopSale.to).format('D-MMM-YY') : ''
    },

    showProveedor() {
      if (!this.stopSale.products.includes('all') && this.stopSale.products.length === 1) {
        return true
      }

      return false
    },
    showDateFrom() {
      if (this.stopSale.products.length > 0) {
        return true
      }

      return false
    },
    showContrate() {
      if (!this.stopSale.proveedor_id.includes(-1) && this.stopSale.proveedor_id.length === 1) {
        return true
      }

      return false
    },
  },
  mounted() {
    /* this.proveedores.push({
      id: this.user.company_id,
      name: this.user.company.name,
    }) */
    this.getProductsPermit()
  },
  created() {
    this.resetItems()
    this.resetTemporadas()

    this.load()

    // this.getProveedoresHotel()
    // this.getProveedoresCar()
    // this.getProveedoresFlights()

    this.getContratesHotel()
    this.getContratesCar()
    this.getContratesFlight()
    this.getCategoriesCar()
    this.getMarcasCar()

    this.getHoteles()
    this.getRooms()
    this.getFlights()
  },
  methods: {
    ...mapMutations([
      'updateStopSalesList',
      'deleteStopSalesList',
      'addDateStopSale',
      'deleteDateStopSale',
      'updateDateStopSale',
    ]),
    load() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('stop-sales/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.items = res.data.data
            this.updateStopSalesList(this.items)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          /* this.products.push({
            slug: 'all',
            name: 'Todos',
          }) */
          const rrr = res.data.data

          this.productsPermit.forEach(elementPermit => {
            rrr.forEach(element => {
              if (elementPermit === element.entity) {
                this.products.push({
                  slug: element.entity,
                  name: element.name,
                })
              } else if (elementPermit === 'vuelos' && element.entity === 'flights') {
                this.products.push({
                  slug: element.entity,
                  name: element.name,
                })
              }
            })
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getProductsPermit() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          if (this.user.company_id) {
            if (this.user.company.products.length > 0) {
              result.forEach(element => {
                if (this.user.company.products.includes(element.slug)) {
                  this.productsPermit.push(element.slug)
                }
              })
            }
          } else {
            result.forEach(element => {
              this.productsPermit.push(element.slug)
            })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getProducts()
        })
    },
    getProveedoresHotel() {
      this.axios
        .post('proveedors/byproduct', { slug: 'hotels', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          res.data.data.forEach(element => {
            if (this.idsProveedoresHotel.includes(element.id)) {
              this.proveedoresHotel.push(element)
            }
          })
        })
    },
    getProveedoresCar() {
      this.axios
        .post('proveedors/byproduct', { slug: 'cars', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          res.data.data.forEach(element => {
            if (this.idsProveedoresCar.includes(element.id)) {
              this.proveedoresCar.push(element)
            }
          })
        })
    },
    getProveedoresFlights() {
      this.axios
        .post('proveedors/byproduct', { slug: 'vuelos', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresFlight = res.data.data
        })
    },
    getContratesHotel() {
      const json = {
        per_page: 10000,
        stopSale: 10,
      }
      this.axios
        .post('contrate_hotels/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesHotel = res.data.data
            this.contratesHotel.forEach(element => {
              this.idsProveedoresHotel.push(element.proveedor.id)
            })
          }
        })
        .finally(() => {
          this.getProveedoresHotel()
        })
    },
    getContratesCar() {
      const json = {
        per_page: 10000,
        stopSale: 10,
      }
      this.axios
        .post('contrate_cars/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesCar = res.data.data
            this.contratesCar.forEach(element => {
              this.idsProveedoresCar.push(element.proveedor.id)
            })
          }
        })
        .finally(() => {
          this.getProveedoresCar()
        })
    },
    getContratesFlight() {
      const json = {
        per_page: 10000,
        stopSale: 10,
      }
      this.axios
        .post('contrate_flights/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesFlight = res.data.data
          }
        })
    },
    getCategoriesCar() {
      this.axios
        .get('nom_categories_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categoriesCar = res.data.data
          }
        })
    },
    getMarcasCar() {
      const json = {
        per_page: 10000,
        search: { marca_id: 0 },
      }
      this.axios
        .post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.marcasCar = res.data.data
          }
        })
    },
    getHoteles() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.hotelesList = res.data.data
          }
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=10000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.roomsList = res.data.data
        })
    },
    getFlights() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('flight/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.flightsList = res.data.data
          }
        })
    },

    setProduct() {
      if (this.stopSale.products) {
        // VERIFICAR SI CONTIENE ALL
        if (!this.stopSale.products.includes('all') && this.stopSale.products.length === 1) {
          /* this.proveedores.push({
            id: -1,
            name: 'Todos',
          }) */
          if (!this.stopSale.id) {
            // this.stopSale.proveedor_id = [this.proveedores[0].id]
            this.setProveedor()
          }

          // BUSCAR LOS PROVEEDORES DE LOS PRODUCTOS
          if (this.stopSale.products[0] === 'hotels') {
            this.proveedoresHotel.forEach(element => {
              this.proveedores.push({
                id: element.id,
                name: element.name_comercial,
              })
            })
          } else if (this.stopSale.products[0] === 'cars') {
            this.proveedoresCar.forEach(element => {
              this.proveedores.push({
                id: element.id,
                name: element.name_comercial,
              })
            })
          } else if (this.stopSale.products[0] === 'flights') {
            this.proveedoresFlight.forEach(element => {
              this.proveedores.push({
                id: element.id,
                name: element.name_comercial,
              })
            })
          }

          // AUTOSELECT
          if (this.proveedores.length === 1) {
            if (!this.stopSale.id) {
              this.stopSale.proveedor_id = [this.proveedores[0].id]
              this.setProveedor()
            }
          }
        } else {
          this.resetProducts()
        }

        /* this.stopSale.from = this.$moment(new Date())
          .toDate()
          .toISOString()
          .substr(0, 10) */
      } else {
        this.resetProducts()
      }
    },
    resetProducts() {
      this.proveedores = []
      this.stopSale.proveedor_id = []
      this.contrates = []
      this.stopSale.contrate_id = []
      this.categoryContrate = []
      this.stopSale.category_id = []
      this.marcasContrate = []
      this.stopSale.marca_modelo_id = []
      this.temporadas = []
      this.stopSale.temporada_id = []
      this.hotelesContrate = []
      this.stopSale.hotel_id = []
      this.roomsContrate = []
      this.stopSale.room_id = []
      this.flightContrate = []
      this.stopSale.flight_id = []
    },
    setProveedor() {
      if (this.stopSale.proveedor_id) {
        if (!this.stopSale.proveedor_id.includes(-1) && this.stopSale.proveedor_id.length === 1) {
          /* this.contrates.push({
            id: -1,
            name: 'Todos',
          }) */

          // BUSCAR LOS CONTRATOS DE LOS PRODUCTOS
          if (this.stopSale.products[0] === 'hotels') {
            this.contratesHotel.forEach(element => {
              if (this.stopSale.proveedor_id[0] === element.proveedor_id) {
                this.contrates.push({
                  id: element.id,
                  name: element.identificador,
                })
              }
            })
          } else if (this.stopSale.products[0] === 'cars') {
            this.contratesCar.forEach(element => {
              if (this.stopSale.proveedor_id[0] === element.proveedor_id) {
                this.contrates.push({
                  id: element.id,
                  name: element.identificador,
                })
              }
            })
          } else if (this.stopSale.products[0] === 'flights') {
            this.contratesFlight.forEach(element => {
              if (this.stopSale.proveedor_id[0] === element.proveedor_id) {
                this.contrates.push({
                  id: element.id,
                  name: element.identificador,
                })
              }
            })
          }

          if (this.contrates.length > 0) {
            if (!this.stopSale.id) {
              // this.stopSale.contrate_id = [this.contrates[0].id]
              // AUTOSELECT
              if (this.contrates.length === 1) {
                if (!this.stopSale.id) {
                  this.stopSale.contrate_id = [this.contrates[0].id]
                }
              }
              this.setContrate()
            }
          }
        } else {
          this.resetProveedor()
        }
      } else {
        this.resetProveedor()
      }
    },
    resetProveedor() {
      this.contrates = []
      this.stopSale.contrate_id = []
      this.categoryContrate = []
      this.stopSale.category_id = []
      this.marcasContrate = []
      this.stopSale.marca_modelo_id = []
      this.temporadas = []
      this.stopSale.temporada_id = []
      this.hotelesContrate = []
      this.stopSale.hotel_id = []
      this.roomsContrate = []
      this.stopSale.room_id = []
      this.flightContrate = []
      this.stopSale.flight_id = []
    },
    setContrate() {
      if (!this.stopSale.contrate_id.includes(-1) && this.stopSale.contrate_id.length === 1) {
        if (this.stopSale.products.includes('cars')) {
          const contrateSelect = this.contratesCar.filter(e => e.id === this.stopSale.contrate_id[0])
          if (contrateSelect.length > 0) {
            this.temporadas.push({
              id: 'all',
              name: 'Todas',
              dates: [],
            })

            /* if (!this.stopSale.id) {
              this.stopSale.temporada_id = [this.temporadas[0].id]
            } */

            if (contrateSelect[0].temporadas.length > 0) {
              contrateSelect[0].temporadas.forEach(seas => {
                this.temporadas.push({
                  id: seas.temporada_id,
                  name: seas.name,
                  dates: seas.dates,
                })
              })

              // AUTOSELECT
              if (this.temporadas.length === 1) {
                this.temporadas.splice(0, 1)
              } else if (this.temporadas.length === 2) {
                this.temporadas.splice(0, 1)
                if (!this.stopSale.id) {
                  this.stopSale.temporada_id = [this.temporadas[0].id]
                }
              }
            }
            if (contrateSelect[0].tipo_contrato === 'categoria') {
              this.categoryContrate.push({
                id: -1,
                name: 'Todas',
              })
              if (contrateSelect[0].categories) {
                contrateSelect[0].categories.forEach(cate => {
                  this.categoryContrate.push({
                    id: cate.category_id.id,
                    name: cate.category_id.name,
                  })
                })
              }

              /* if (!this.stopSale.id) {
                this.stopSale.category_id = [this.categoryContrate[0].id]
              } */
              // AUTOSELECT
              if (this.categoryContrate.length === 1) {
                this.categoryContrate.splice(0, 1)
              } else if (this.categoryContrate.length === 2) {
                this.categoryContrate.splice(0, 1)
                if (!this.stopSale.id) {
                  this.stopSale.category_id = [this.categoryContrate[0].id]
                }
              }
            } else {
              this.marcasContrate.push({
                id: -1,
                name: 'Todas',
              })
              if (contrateSelect[0].marcas) {
                contrateSelect[0].marcas.forEach(mar => {
                  mar.cars_id.forEach(car => {
                    this.marcasContrate.push({
                      id: car.id,
                      name: car.name,
                    })
                  })
                })
              }

              /* if (!this.stopSale.id) {
                this.stopSale.marca_modelo_id = [this.marcasContrate[0].id]
              } */
              // AUTOSELECT
              if (this.marcasContrate.length === 1) {
                this.marcasContrate.splice(0, 1)
              } else if (this.marcasContrate.length === 2) {
                this.marcasContrate.splice(0, 1)
                if (!this.stopSale.id) {
                  this.stopSale.marca_modelo_id = [this.marcasContrate[0].id]
                }
              }
            }
          }
        } else if (this.stopSale.products.includes('hotels')) {
          const contrateSelect = this.contratesHotel.filter(e => e.id === this.stopSale.contrate_id[0])
          if (contrateSelect.length > 0) {
            this.hotelesContrate.push({
              id: -1,
              name: 'Todos',
            })
            const hotelesPactados = contrateSelect[0].hotels_pactados

            hotelesPactados.forEach(hote => {
              const ppp = this.hotelesList.filter(e => e.id === hote)
              if (ppp.length > 0) {
                this.hotelesContrate.push({
                  id: ppp[0].id,
                  name: ppp[0].name,
                })
              }
            })

            /* if (!this.stopSale.id) {
              this.stopSale.hotel_id = [this.hotelesContrate[0].id]
            } */
            // AUTOSELECT
            if (this.hotelesContrate.length === 1) {
              this.hotelesContrate.splice(0, 1)
            } else if (this.hotelesContrate.length === 2) {
              this.hotelesContrate.splice(0, 1)
              if (!this.stopSale.id) {
                this.stopSale.hotel_id = [this.hotelesContrate[0].id]
                this.setHotel()
              }
            }
          }
        } else if (this.stopSale.products.includes('flights')) {
          const contrateSelect = this.contratesFlight.filter(e => e.id === this.stopSale.contrate_id[0])
          if (contrateSelect.length > 0) {
            this.flightContrate.push({
              id: -1,
              name: 'Todos',
            })
            const flightId = contrateSelect[0].flight
            if (flightId !== null) {
              this.flightContrate.push({
                id: flightId.id,
                name: flightId.numero_de_vuelo,
              })
            }

            /* if (!this.stopSale.id) {
              this.stopSale.flight_id = [this.flightContrate[0].id]
            } */
            // AUTOSELECT
            if (this.flightContrate.length === 1) {
              this.flightContrate.splice(0, 1)
            } else if (this.flightContrate.length === 2) {
              this.flightContrate.splice(0, 1)
              if (!this.stopSale.id) {
                this.stopSale.flight_id = [this.flightContrate[0].id]
              }
            }
          }
        }
      } else {
        this.resetContrate()
      }
    },
    resetContrate() {
      this.categoryContrate = []
      this.stopSale.category_id = []
      this.marcasContrate = []
      this.stopSale.marca_modelo_id = []
      this.temporadas = []
      this.stopSale.temporada_id = []
      this.hotelesContrate = []
      this.stopSale.hotel_id = []
      this.roomsContrate = []
      this.stopSale.room_id = []
      this.flightContrate = []
      this.stopSale.flight_id = []
    },
    setTemporada() {
      if (!this.stopSale.temporada_id.includes('all') && this.stopSale.temporada_id.length === 1) {
        const thisSeason = this.temporadas.filter(e => e.id === this.stopSale.temporada_id[0])
        if (thisSeason.length > 0) {
          if (thisSeason[0].dates.length > 0) {
            if (thisSeason[0].dates[0].from) {
              /* if (this.stopSale.id === null) {
                this.stopSale.from = this.$moment(thisSeason[0].dates[0].from)
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
              } */
              this.minDate = this.$moment(thisSeason[0].dates[0].from)
            }
            if (thisSeason[0].dates[thisSeason[0].dates.length - 1].to) {
              /* if (this.stopSale.id === null) {
                this.stopSale.to = this.$moment(thisSeason[0].dates[thisSeason[0].dates.length - 1].to)
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
              } */

              this.maxDate = this.$moment(thisSeason[0].dates[thisSeason[0].dates.length - 1].to)
            }
          }
        }
      } else if (!this.stopSale.id) {
        this.resetTemporadas()
      }
    },
    resetTemporadas() {
      if (this.stopSale.id === null) {
        this.minDate = this.$moment(Date.now())
        if (this.stopSale.from) {
          const y = this.$moment(this.stopSale.from).format('Y')
          const m = this.$moment(this.stopSale.from).format('M')
          if (m > 10) {
            const ny = parseInt(y, 10) + 1

            /* this.stopSale.to = this.$moment(`${ny}-10-31`)
              .toDate()
              .toISOString()
              .substr(0, 10) */
            this.maxDate = this.$moment(`${ny}-10-31`)
          } else {
            /* this.stopSale.to = this.$moment(`${y}-10-31`)
              .toDate()
              .toISOString()
              .substr(0, 10) */
            this.maxDate = this.$moment(`${y}-10-31`)
          }
        }
      }
    },

    setHotel() {
      if (!this.stopSale.hotel_id.includes('all') && this.stopSale.hotel_id.length === 1) {
        const thisHotel = this.hotelesList.filter(e => e.id === this.stopSale.hotel_id[0])
        if (thisHotel.length > 0) {
          const arrSeason = []
          const cont = this.contratesHotel.filter(e => e.id === this.stopSale.contrate_id[0])
          if (cont.length > 0) {
            const { seasons } = cont[0]
            if (seasons.length > 0) {
              seasons.forEach(seas => {
                if (seas.hotels.includes(this.stopSale.hotel_id[0])) {
                  if (seas.seasons.seasons.length > 0) {
                    seas.seasons.seasons.forEach(ace => {
                      arrSeason.push({
                        id: ace.temporada_id,
                        name: ace.name,
                        dates: [
                          {
                            from: ace.from,
                            to: ace.to,
                          },
                        ],
                      })
                    })
                  }
                }
              })
            }
          }

          this.temporadas.push({
            id: 'all',
            name: 'Todas',
            dates: [],
          })
          arrSeason.forEach(arrS => {
            this.temporadas.push(arrS)
          })
          if (!this.stopSale.id) {
            this.stopSale.temporada_id = [this.temporadas[0].id]
          }

          /* if (contrateSelect[0].temporadas.length > 0) {
            contrateSelect[0].temporadas.forEach(seas => {
              this.temporadas.push({
                id: seas.temporada_id,
                name: seas.name,
                dates: seas.dates,
              })
            })
          } */

          const roomHotel = thisHotel[0].rooms_id
          this.roomsContrate.push({
            id: -1,
            name: 'Todas',
          })
          roomHotel.forEach(roo => {
            const ppp = this.roomsList.filter(e => e.id === roo)
            if (ppp.length > 0) {
              this.roomsContrate.push({
                id: ppp[0].id,
                name: ppp[0].name,
              })
            }
          })

          /* if (!this.stopSale.id) {
            this.stopSale.room_id = [this.roomsContrate[0].id]
          } */
          // AUTOSELECT
          if (this.roomsContrate.length === 1) {
            this.roomsContrate.splice(0, 1)
          } else if (this.roomsContrate.length === 2) {
            this.roomsContrate.splice(0, 1)
            if (!this.stopSale.id) {
              this.stopSale.room_id = [this.roomsContrate[0].id]
            }
          }
        }
      } else if (!this.stopSale.id) {
        this.resetHotel()
      } else if (this.stopSale.hotel_id.length >= 2) {
        this.resetHotel()
      }
    },
    resetHotel() {
      this.roomsContrate = []
      this.stopSale.room_id = []
      this.temporadas = []
      this.stopSale.temporada_id = []
    },

    asignMarkups(id) {
      this.categoryContrate = []
      this.marcasContrate = []
      this.hotelesContrate = []
      this.roomsContrate = []
      this.temporadas = []

      const mmm = this.items.filter(e => e.id === id)
      if (mmm.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.stopSale = mmm[0]
        this.updateDateStopSale(mmm[0].dates)
      }

      this.setProduct()

      this.setProveedor()
      this.setContrate()
      this.setHotel()
      this.setTemporada()
    },
    save() {
      if (this.stopSale.products.length > 0) {
        let procesar = true
        this.datesStopSale.forEach(element => {
          if (!element.from || !element.to) {
            procesar = false
          }
        })
        if (
          this.stopSale.contrate_id.length === 0
          || (this.stopSale.contrate_id.length === 1 && this.stopSale.contrate_id === -1)
        ) {
          procesar = false
        }
        if (procesar) {
          this.stopSale.dates = this.datesStopSale
          this.loading = true
          if (this.stopSale.id === null) {
            this.axios
              .post('stop-sales', this.stopSale, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                // console.log(response)
                if (response.data.success === false) {
                  if (response.data.data.status === 401) {
                    // this.$toast.error(this.$t('msg.noAutorice'))
                  }
                } else {
                  this.load()
                  this.$toast.success(this.$t('msg.infoSuccess'))
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => this.resetItems())
          } else {
            this.axios
              .put(`stop-sales/${this.stopSale.id}`, this.stopSale, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                if (response.data.success === false) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else {
                  this.load()
                  window.scrollTo(0, 10000)
                  this.$toast.success(this.$t('msg.infoSuccess'))
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => this.resetItems())
          }
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearItem() {
      // DETECTAR SI HUBO CAMBIOS
      this.continueClear()

      // this.resetItems()
      // this.emptyOfertEBB()
    },
    continueClear() {
      this.changesNotUpdate = false
      this.resetItems()
      this.load()

      // this.emptyOfertEBB()
    },

    resetItems() {
      this.categoryContrate = []
      this.marcasContrate = []
      this.hotelesContrate = []
      this.roomsContrate = []
      this.flightContrate = []
      this.temporadas = []
      this.contrates = []
      this.stopSale = {
        id: null,
        products: [],

        // proveedor_id: [this.user.company_id],
        proveedor_id: [],
        contrate_id: [],

        // CARS
        category_id: [],
        marca_modelo_id: [],

        // HOTELS
        hotel_id: [],
        room_id: [],

        // FLIGHTS
        aeroline_id: [],
        flight_id: [],

        temporada_id: [],
      }

      this.updateDateStopSale([
        {
          from: null,
          to: null,
        },
      ])

      this.loading = false
      this.showTabs = true
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
