<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <tbody>
          <!--PRODUCTS-->
          <fragment v-if="user.nivel <= 0">
            <tr>
              <th>
                {{ $t('lbl.product') }}
              </th>
              <th></th>
              <th class="text-right">
                <v-btn
                  icon
                  @click="showItemProduct()"
                >
                  <v-icon>
                    {{ showRowProduct ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </th>
            </tr>
            <fragment v-if="showRowProduct">
              <tr>
                <th class="text-uppercase">
                  <b>{{ $t('lbl.identificador') }}</b>
                </th>
                <th>
                  <b>{{ $t('lbl.dates') }}</b>
                </th>

                <th
                  v-if="
                    permisos.includes('stopSale:edit') ||
                      permisos.includes('stopSale:destroy') ||
                      permisos.includes('*')
                  "
                  width="150px"
                  class="text-uppercase"
                >
                  <b>{{ $t('lbl.actions') }}</b>
                </th>
              </tr>

              <tr
                v-for="(mark, indM) in itemsProduct"
                :key="indM"
              >
                <td>
                  {{ mark.identificador }}
                </td>
                <td>
                  <span
                    v-for="(date, indD) in mark.dates"
                    :key="indD"
                  >
                    {{ $moment(date.from).format('D-MMM-YY') }} /
                    {{ date.to ? $moment(date.to).format('D-MMM-YY') : 'Indefinido' }}<br />
                  </span>
                </td>
                <td
                  v-if="
                    permisos.includes('stopSale:edit') ||
                      permisos.includes('stopSale:destroy') ||
                      permisos.includes('*')
                  "
                >
                  <v-tooltip
                    v-if="permisos.includes('stopSale:edit') || permisos.includes('*')"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="editItem(mark)"
                      >
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="permisos.includes('stopSale:destroy') || permisos.includes('*')"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItem(mark)"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </fragment>
          </fragment>

          <!--PROVEEDOR-->
          <fragment v-if="user.nivel <= 0">
            <tr>
              <th
                colspan="4"
                style="height: 10px"
              ></th>
            </tr>
            <tr>
              <th>
                {{ $t('lbl.proveedor') }}
              </th>
              <th></th>
              <th class="text-right">
                <v-btn
                  icon
                  @click="showItemProveedor()"
                >
                  <v-icon>
                    {{ showRowProveedor ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </th>
            </tr>
            <fragment v-if="showRowProveedor">
              <tr>
                <th class="text-uppercase">
                  <b>{{ $t('lbl.identificador') }}</b>
                </th>
                <th>
                  <b>{{ $t('lbl.dates') }}</b>
                </th>
                <th
                  v-if="
                    permisos.includes('stopSale:edit') ||
                      permisos.includes('stopSale:destroy') ||
                      permisos.includes('*')
                  "
                  width="150px"
                  class="text-uppercase"
                >
                  <b>{{ $t('lbl.actions') }}</b>
                </th>
              </tr>

              <tr
                v-for="(mark, indM) in itemsProveedor"
                :key="indM"
              >
                <td>
                  {{ mark.identificador }}
                </td>
                <td>
                  <span
                    v-for="(date, indD) in mark.dates"
                    :key="indD"
                  >
                    {{ $moment(date.from).format('D-MMM-YY') }} /
                    {{ date.to ? $moment(date.to).format('D-MMM-YY') : 'Indefinido' }}<br />
                  </span>
                </td>
                <td
                  v-if="
                    permisos.includes('stopSale:edit') ||
                      permisos.includes('stopSale:destroy') ||
                      permisos.includes('*')
                  "
                >
                  <v-tooltip
                    v-if="permisos.includes('stopSale:edit') || permisos.includes('*')"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="editItem(mark)"
                      >
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="permisos.includes('stopSale:destroy') || permisos.includes('*')"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItem(mark)"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </fragment>
          </fragment>

          <tr>
            <th
              colspan="4"
              style="height: 10px"
            ></th>
          </tr>
          <!--CONTRATE-->
          <tr>
            <th>
              {{ $t('lbl.contrato') }}
            </th>
            <th></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemContrate()"
              >
                <v-icon>
                  {{ showRowContrate ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowContrate">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.dates') }}</b>
              </th>

              <th
                v-if="
                  permisos.includes('stopSale:edit') || permisos.includes('stopSale:destroy') || permisos.includes('*')
                "
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsContrate"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span
                  v-for="(date, indD) in mark.dates"
                  :key="indD"
                >
                  {{ $moment(date.from).format('D-MMM-YY') }} /
                  {{ date.to ? $moment(date.to).format('D-MMM-YY') : 'Indefinido' }}<br />
                </span>
              </td>
              <td
                v-if="
                  permisos.includes('stopSale:edit') || permisos.includes('stopSale:destroy') || permisos.includes('*')
                "
              >
                <v-tooltip
                  v-if="permisos.includes('stopSale:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('stopSale:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              colspan="4"
              style="height: 10px"
            ></th>
          </tr>
          <!--CATEGORY-->
          <tr>
            <th>
              {{ $t('lbl.cat') }}
            </th>
            <th></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemCategory()"
              >
                <v-icon>
                  {{ showRowCategory ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowCategory">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.dates') }}</b>
              </th>

              <th
                v-if="
                  permisos.includes('stopSale:edit') || permisos.includes('stopSale:destroy') || permisos.includes('*')
                "
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsCategory"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span
                  v-for="(date, indD) in mark.dates"
                  :key="indD"
                >
                  {{ $moment(date.from).format('D-MMM-YY') }} /
                  {{ date.to ? $moment(date.to).format('D-MMM-YY') : 'Indefinido' }}<br />
                </span>
              </td>
              <td
                v-if="
                  permisos.includes('stopSale:edit') || permisos.includes('stopSale:destroy') || permisos.includes('*')
                "
              >
                <v-tooltip
                  v-if="permisos.includes('stopSale:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('stopSale:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              colspan="4"
              style="height: 10px"
            ></th>
          </tr>
          <!--MARCA MODELO-->
          <tr>
            <th>
              {{ $t('lbl.brandModel') }}
            </th>
            <th></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemMarca()"
              >
                <v-icon>
                  {{ showRowMarca ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowMarca">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.dates') }}</b>
              </th>
              <th
                v-if="
                  permisos.includes('stopSale:edit') || permisos.includes('stopSale:destroy') || permisos.includes('*')
                "
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsMarcas"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span
                  v-for="(date, indD) in mark.dates"
                  :key="indD"
                >
                  {{ $moment(date.from).format('D-MMM-YY') }} /
                  {{ date.to ? $moment(date.to).format('D-MMM-YY') : 'Indefinido' }}<br />
                </span>
              </td>
              <td
                v-if="
                  permisos.includes('stopSale:edit') || permisos.includes('stopSale:destroy') || permisos.includes('*')
                "
              >
                <v-tooltip
                  v-if="permisos.includes('stopSale:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('stopSale:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              colspan="4"
              style="height: 10px"
            ></th>
          </tr>
          <!--TEMPORADAS--
          <tr>
            <th>
              {{ $t('lbl.temporadas') }}
            </th>
            <th></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemTemporada()"
              >
                <v-icon>
                  {{ showRowTemporada ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowTemporada">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.dates') }}</b>
              </th>

              <th
                v-if="
                  permisos.includes('stopSale:edit') || permisos.includes('stopSale:destroy') || permisos.includes('*')
                "
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsTemporada"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span
                  v-for="(date, indD) in mark.dates"
                  :key="indD"
                >
                  {{ $moment(date.from).format('D-MMM-YY') }} /
                  {{ date.to ? $moment(date.to).format('D-MMM-YY') : 'Indefinido' }}<br />
                </span>
              </td>
              <td
                v-if="
                  permisos.includes('stopSale:edit') || permisos.includes('stopSale:destroy') || permisos.includes('*')
                "
              >
                <v-tooltip
                  v-if="permisos.includes('stopSale:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('stopSale:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>-->
        </tbody>
      </template>
    </v-simple-table>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteStopSale') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    proveedoresCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    contratesCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    categoriesCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    marcasCar: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowCategory: false,
      showRowMarca: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      model: {},
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      proveedores: [],
      aerolineas: [],

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      stopSalesList: state => state.app.stopSalesList,
    }),
    itemsProduct() {
      if (this.stopSalesList.length > 0) {
        const newArr = []
        this.stopSalesList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            let identificador = ''
            if (element.products.includes('all')) {
              identificador = 'Autos'

              // identificador = 'Todos'
            } else {
              identificador = 'Autos'

              /* if (element.products.includes('hotels')) {
                identificador += ', Hoteles'
              }
              if (element.products.includes('flights')) {
                identificador += ', Vuelos'
              } */
            }

            if (
              (element.proveedor_id.length === 0
                || (element.proveedor_id.length === 1 && element.proveedor_id.includes(-1)))
              && element.contrate_id.length === 0
              && element.category_id.length === 0
              && element.marca_modelo_id.length === 0
              && element.temporada_id.length === 0
            ) {
              newArr.push({
                identificador,
                ...element,
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsProveedor() {
      if (this.stopSalesList.length > 0) {
        const newArr = []
        this.stopSalesList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length > 0
              && !element.proveedor_id.includes(-1)
              && ((element.contrate_id.length === 1 && element.contrate_id.includes(-1))
                || element.contrate_id.length === 0)
              && element.category_id.length === 0
              && element.marca_modelo_id.length === 0
              && element.temporada_id.length === 0
            ) {
              /* element.proveedor_id.forEach(provee => {
                let identificador = ''
                const ppp = this.proveedoresCar.filter(e => e.id === provee)
                if (ppp.length > 0) {
                  identificador = ppp[0].name_comercial
                } else {
                  identificador = 'Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              }) */
              let identificador = ''
              element.proveedor_id.forEach(provee => {
                const ppp = this.proveedoresCar.filter(e => e.id === provee)
                if (ppp.length > 0) {
                  if (identificador === '') {
                    identificador = ppp[0].name_comercial
                  } else {
                    identificador = `${identificador}, ${ppp[0].name_comercial}`
                  }
                } else {
                  identificador = 'Todos'
                }
              })
              newArr.push({
                identificador,
                ...element,
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsContrate() {
      if (this.stopSalesList.length > 0) {
        const newArr = []
        this.stopSalesList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && element.contrate_id.length > 0
              && !element.contrate_id.includes(-1)
              && element.category_id.length === 0
              && element.marca_modelo_id.length === 0

            // && element.temporada_id.length === 1
            // && element.temporada_id.includes('all')
            ) {
              /* element.contrate_id.forEach(ite => {
                let identificador = ''
                const ppp = this.contratesCar.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  identificador = ppp[0].identificador
                } else {
                  identificador = 'Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              }) */
              let identificador = ''
              element.contrate_id.forEach(ite => {
                const ppp = this.contratesCar.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  if (identificador === '') {
                    identificador = ppp[0].identificador
                  } else {
                    identificador = `${identificador}, ${ppp[0].identificador}`
                  }
                } else {
                  identificador = 'Todos'
                }
              })
              newArr.push({
                identificador,
                ...element,
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsCategory() {
      if (this.stopSalesList.length > 0) {
        const newArr = []
        this.stopSalesList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && element.category_id.length > 0
              && !element.category_id.includes(-1)
              && element.marca_modelo_id.length === 0

            // && element.temporada_id.length === 1
            // && element.temporada_id.includes('all')
            ) {
              let identificador = ''
              const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
              if (cont.length > 0) {
                identificador = cont[0].identificador
              }
              let nameCategory = ''
              element.category_id.forEach(ite => {
                const ppp = this.categoriesCar.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  if (nameCategory === '') {
                    nameCategory = ppp[0].name
                  } else {
                    nameCategory = `${nameCategory}, ${ppp[0].name}`
                  }
                } else {
                  identificador += ', Todas'
                }
              })
              newArr.push({
                identificador: `${identificador}, ${nameCategory}`,
                ...element,
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsMarcas() {
      if (this.stopSalesList.length > 0) {
        const newArr = []
        this.stopSalesList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && element.category_id.length === 0
              && element.marca_modelo_id.length > 0

            // && element.temporada_id.length === 1
            // && element.temporada_id.includes('all')
            ) {
              let identificador = ''
              const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
              if (cont.length > 0) {
                identificador = cont[0].identificador
              }
              let nameMarca = ''
              element.marca_modelo_id.forEach(ite => {
                const ppp = this.marcasCar.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  if (nameMarca === '') {
                    nameMarca = ppp[0].name
                  } else {
                    nameMarca = `${nameMarca}, ${ppp[0].name}`
                  }
                } else {
                  nameMarca = 'Todas'
                }
              })
              newArr.push({
                identificador: `${identificador}, ${nameMarca}`,
                ...element,
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsTemporada() {
      if (this.stopSalesList.length > 0) {
        const newArr = []
        this.stopSalesList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && (element.category_id.length > 0 || element.marca_modelo_id.length > 0)
              && element.temporada_id.length > 0
              && !element.temporada_id.includes('all')
            ) {
              // eslint-disable-next-line no-unused-vars
              element.temporada_id.forEach(ite => {
                let identificador = ''
                const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
                if (cont.length > 0) {
                  identificador = cont[0].identificador
                  // eslint-disable-next-line no-empty
                  if (cont[0].tipo_contrato === 'categoria') {
                    if (element.category_id.length > 0) {
                      let nameSeason = 'Todas'
                      if (ite !== 'all') {
                        const sss = cont[0].temporadas
                        sss.forEach(seasss => {
                          if (seasss.temporada_id === ite) {
                            nameSeason = seasss.name
                          }
                        })
                      }
                      element.category_id.forEach(cate => {
                        const ppp = this.categoriesCar.filter(e => e.id === cate)
                        let nameCat = 'Todas'
                        if (ppp.length > 0) {
                          nameCat = ppp[0].name
                        }

                        newArr.push({
                          identificador: `${identificador}, ${nameCat}, ${nameSeason}`,
                          ...element,
                        })
                      })
                    }
                  } else if (element.marca_modelo_id.length > 0) {
                    let nameSeason = 'Todas'
                    if (ite !== 'all') {
                      const sss = cont[0].temporadas
                      sss.forEach(seasss => {
                        if (seasss.temporada_id === ite) {
                          nameSeason = seasss.name
                        }
                      })
                    }
                    element.marca_modelo_id.forEach(marc => {
                      const ppp = this.marcasCar.filter(e => e.id === marc)
                      let nameMarc = 'Todas'
                      if (ppp.length > 0) {
                        nameMarc = ppp[0].name
                      }

                      newArr.push({
                        identificador: `${identificador}, ${nameMarc}, ${nameSeason}`,
                        ...element,
                      })
                    })
                  }
                }
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsDates() {
      if (this.stopSalesList.length > 0) {
        const newArr = []
        this.stopSalesList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && (element.category_id.length > 0 || element.marca_modelo_id.length > 0)
              && element.temporada_id.length > 0
              && !element.temporada_id.includes('all')
              && element.from
              && element.to
            ) {
              // eslint-disable-next-line no-unused-vars
              let identificador = ''
              const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
              if (cont.length > 0) {
                identificador = cont[0].identificador
                // eslint-disable-next-line no-empty
                if (cont[0].tipo_contrato === 'categoria') {
                  if (element.category_id.length > 0) {
                    element.category_id.forEach(cate => {
                      const ppp = this.categoriesCar.filter(e => e.id === cate)
                      let nameCat = 'Todas'
                      if (ppp.length > 0) {
                        nameCat = ppp[0].name
                      }

                      newArr.push({
                        identificador: `${identificador}, ${nameCat}, ${this.$moment(element.from).format(
                          'D/MMM/YY',
                        )} - ${this.$moment(element.to).format('D/MMM/YY')}`,
                        ...element,
                      })
                    })
                  }
                } else if (element.marca_modelo_id.length > 0) {
                  element.marca_modelo_id.forEach(marc => {
                    const ppp = this.marcasCar.filter(e => e.id === marc)
                    let nameMarc = 'Todas'
                    if (ppp.length > 0) {
                      nameMarc = ppp[0].name
                    }

                    newArr.push({
                      identificador: `${identificador}, ${nameMarc}, ${this.$moment(element.from).format(
                        'D/MMM/YY',
                      )} - ${this.$moment(element.to).format('D/MMM/YY')}`,
                      ...element,
                    })
                  })
                }
              }
            }
          }
        })

        return newArr
      }

      return []
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)

    // this.loadAll()
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList']),
    showItemProduct() {
      this.showRowProduct = !this.showRowProduct
    },
    showItemProveedor() {
      this.showRowProveedor = !this.showRowProveedor
    },
    showItemContrate() {
      this.showRowContrate = !this.showRowContrate
    },
    showItemCategory() {
      this.showRowCategory = !this.showRowCategory
    },
    showItemMarca() {
      this.showRowMarca = !this.showRowMarca
    },
    showItemTemporada() {
      this.showRowTemporada = !this.showRowTemporada
    },
    showItemDate() {
      this.showRowDate = !this.showRowDate
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.$store.state.app.itemsPerPage,
        nom_tipo_product_slug: 'cars',
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('stop-sales/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    editItem(mark) {
      this.$emit('asign:markups', mark.id)
      window.scrollTo(0, 0)
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .delete(`stop-sales/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.$emit('load')
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.reseat())
    },
    reseat() {
      this.loading = false
      this.isDialogVisibleDelete = false
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
