var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isLoading)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('lbl.product'))+" ")]),_c('th'),_c('th',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showItemProduct()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.showRowProduct ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])],1)],1)]),(_vm.showRowProduct)?_c('fragment',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.identificador')))])]),_c('th',[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.dates')))])]),(
                _vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*')
              )?_c('th',{staticClass:"text-uppercase",attrs:{"width":"150px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.actions')))])]):_vm._e()]),_vm._l((_vm.itemsProduct),function(mark,indM){return _c('tr',{key:indM},[_c('td',[_vm._v(" "+_vm._s(mark.identificador)+" ")]),_c('td',_vm._l((mark.dates),function(date,indD){return _c('span',{key:indD},[_vm._v(" "+_vm._s(_vm.$moment(date.from).format('D-MMM-YY'))+" / "+_vm._s(date.to ? _vm.$moment(date.to).format('D-MMM-YY') : 'Indefinido')),_c('br')])}),0),(
                _vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*')
              )?_c('td',[(_vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),(_vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1):_vm._e()])})],2):_vm._e(),(_vm.user.nivel <= 0)?_c('fragment',[_c('tr',[_c('th',{staticStyle:{"height":"10px"},attrs:{"colspan":"3"}})]),_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('lbl.proveedor'))+" ")]),_c('th'),_c('th',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showItemProveedor()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.showRowProveedor ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])],1)],1)]),(_vm.showRowProveedor)?_c('fragment',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.identificador')))])]),_c('th',[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.dates')))])]),(
                  _vm.permisos.includes('stopSale:edit') ||
                    _vm.permisos.includes('stopSale:destroy') ||
                    _vm.permisos.includes('*')
                )?_c('th',{staticClass:"text-uppercase",attrs:{"width":"150px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.actions')))])]):_vm._e()]),_vm._l((_vm.itemsProveedor),function(mark,indM){return _c('tr',{key:indM},[_c('td',[_vm._v(" "+_vm._s(mark.identificador)+" ")]),_c('td',_vm._l((mark.dates),function(date,indD){return _c('span',{key:indD},[_vm._v(" "+_vm._s(_vm.$moment(date.from).format('D-MMM-YY'))+" / "+_vm._s(date.to ? _vm.$moment(date.to).format('D-MMM-YY') : 'Indefinido')),_c('br')])}),0),(
                  _vm.permisos.includes('stopSale:edit') ||
                    _vm.permisos.includes('stopSale:destroy') ||
                    _vm.permisos.includes('*')
                )?_c('td',[(_vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),(_vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1):_vm._e()])})],2):_vm._e()],1):_vm._e(),_c('tr',[_c('th',{staticStyle:{"height":"10px"},attrs:{"colspan":"3"}})]),_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('lbl.contrato'))+" ")]),_c('th'),_c('th',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showItemContrate()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.showRowContrate ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])],1)],1)]),(_vm.showRowContrate)?_c('fragment',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.identificador')))])]),_c('th',[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.dates')))])]),(
                _vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*')
              )?_c('th',{staticClass:"text-uppercase",attrs:{"width":"150px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.actions')))])]):_vm._e()]),_vm._l((_vm.itemsContrate),function(mark,indM){return _c('tr',{key:indM},[_c('td',[_vm._v(" "+_vm._s(mark.identificador)+" ")]),_c('td',_vm._l((mark.dates),function(date,indD){return _c('span',{key:indD},[_vm._v(" "+_vm._s(_vm.$moment(date.from).format('D-MMM-YY'))+" / "+_vm._s(date.to ? _vm.$moment(date.to).format('D-MMM-YY') : 'Indefinido')),_c('br')])}),0),(
                _vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*')
              )?_c('td',[(_vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),(_vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1):_vm._e()])})],2):_vm._e(),_c('tr',[_c('th',{staticStyle:{"height":"10px"},attrs:{"colspan":"3"}})]),_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('lbl.vuelo'))+" ")]),_c('th'),_c('th',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showItemFlight()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.showRowFlight ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])],1)],1)]),(_vm.showRowFlight)?_c('fragment',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.identificador')))])]),_c('th',[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.dates')))])]),(
                _vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*')
              )?_c('th',{staticClass:"text-uppercase",attrs:{"width":"150px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.actions')))])]):_vm._e()]),_vm._l((_vm.itemsFlights),function(mark,indM){return _c('tr',{key:indM},[_c('td',[_vm._v(" "+_vm._s(mark.identificador)+" ")]),_c('td',_vm._l((mark.dates),function(date,indD){return _c('span',{key:indD},[_vm._v(" "+_vm._s(_vm.$moment(date.from).format('D-MMM-YY'))+" / "+_vm._s(date.to ? _vm.$moment(date.to).format('D-MMM-YY') : 'Indefinido')),_c('br')])}),0),(
                _vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*')
              )?_c('td',[(_vm.permisos.includes('stopSale:edit') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),(_vm.permisos.includes('stopSale:destroy') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1):_vm._e()])})],2):_vm._e()],1)]},proxy:true}],null,false,1980646018)}):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":"380px"},model:{value:(_vm.isDialogVisibleDelete),callback:function ($$v) {_vm.isDialogVisibleDelete=$$v},expression:"isDialogVisibleDelete"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(("" + (_vm.$t('btn.delete')))))]),_c('v-divider'),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('msg.deleteStopSale')))]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }